.uk-position-center
  top: 75%;

  @media (hover: hover) and (min-width: 992px)
    top: 60%;

.uk-height-large
  height: 280px;

.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span
  color: #d79e0a;

.woocommerce .uk-section
  padding-top: 0;

.promo > div
  padding: 150px 15% 2px

  @media (hover: hover) and (min-width: 992px)
    padding: 105px 15% 50px;

.woocommerce a.remove:hover
  background transparent

body.hidden
  overflow hidden

.disable-hover,
.disable-hover *
  pointer-events: none !important;

.goods__item-box .added_to_cart.wc-forward
  display: none


/* POPUP */

.popup
  display none
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  background rgba(255,255,255,.9)
  z-index 9999
  min-height 520px
  
  &__layer
    position absolute
    left 0
    top 0
    width 100%
    height 100%

  &__text-box
    max-width 480px
    margin 0 auto
    padding 25px 20px
    text-align center
    background #fff
    font-size 18px

  &__box
    position absolute
    left 50%
    top 50%
    transform translate(-50%,-50%)
    width: 100%;

  &__link
    display block
    max-width 220px
    width 100%
    text-align center
    color #fff
    border 1px solid #d79e0a
    background #d79e0a
    border-radius 20px
    margin 0 auto 20px
    padding: 7px 10px 10px;
    text-decoration none
    transition .27s all

    &:last-child
      margin 0 auto
    
    &:hover
      text-decoration none
      color #fff

      @media (hover: hover) and (min-width: 992px)
        background transparent
        color #d79e0a

  &__form
    max-width 28rem
    margin 0 auto

  &__input
    margin 2.4rem 0 0
    height 5.8rem
    font-size: 1.8rem;

  &__title
    font-size 24px
    padding 0 0 20px


/* CONTENT */

.link-booking
  font-size: 0
  border-radius 50%
  padding: 4px;
  fill #b8860b

  @media (min-width: 992px)
    font-size: inherit
    border-radius 0
    padding 0 15px

  svg

    @media (min-width: 992px)
      display: none

.link-basket
  font-size: 16px;
  line-height: 1.2;
  color: #fff
  background: rgba(34, 34, 34, 0.8);
  padding: 2px 15px 2px 45px
  position: relative
  cursor pointer
  position: absolute
  top: 82px;
  right: 0;
  border: 1px solid #fff;
  border-radius 20px
  transition: .27s all

  @media (min-width: 992px)
    margin: 0 18px 0 0;
    position: relative
    top: auto
    right: auto

  &:hover
    @media (hover: hover) and (min-width: 992px)
      border: 1px solid #D79E0A

  &__ico
    position: absolute
    left: 10px
    top 50%
    margin: -11px 0 0
    width 22px
    height 22px
    fill: #fff
    transition: .27s all

  &:hover &__ico
    @media (hover: hover) and (min-width: 992px)
      fill: #D79E0A

  span
    color: #D79E0A

.closer
  position: absolute
  right: 30px
  top 18px
  width: 24px;
  height: 24px;
  z-index: 2;

  @media (min-width: 580px)
    top 30px

  &:hover
    &:before,
    &:after
      @media (hover: hover) and (min-width: 992px)
        background: #b8860b;

  &:before,
  &:after
    content: ""
    position: absolute;
    top: -3px;
    right: 10px;
    width: 4px;
    height: 130%;
    content: '';
    transition: .27s background;
    border-radius: 3px;
    background: #000;

  &:before
    transform: rotate(-45deg);

  &:after
    transform: rotate(45deg);

.menu
  background: #222;

.content
  max-width: 1920px
  width 100%
  margin: 0 auto
  display: flex
  background: #222
  gap: 0 44px;
  padding: 0 0 180px

.dishes-link
  position: fixed
  background #D79E0A
  color #fff
  font-size: 15px
  top 50%
  left -4px
  border-radius: 10px 10px 0 0
  padding: 7px 12px
  letter-spacing: 2px;
  transform rotate(90deg) translate(-50%, 50%)

  &:hover
    color #fff
    text-decoration: none

  @media (min-width: 992px)
    display: none

.dishes
  background: #E7E2E2
  display: flex;
  flex-direction: column;
  gap 14px 0
  padding: 42px 0;
  position: fixed
  left: 0
  top: 0
  bottom: 0
  width 100%
  transform: translate(-100%, 0);
  opacity 0
  z-index: 999;
  transition: .27s all

  &.show
    transform: translate(0, 0);
    opacity 1

  @media (min-width: 992px)
    width 320px
    position: relative
    max-width: 418px
    min-width 320px
    width auto
    transform: none
    opacity 1
    z-index: 1;

  &__wrap
    position: absolute;
    top: 46px
    bottom:23px
    right 0
    left: 0
    overflow: hidden;

    @media (min-width: 580px)
      top: 33px;
      bottom: 68px;

    @media (min-width: 992px)
      position: relative;
      top: auto
      bottom: auto
      overflow: auto;

  &__box
    position: absolute
    overflow-y: scroll;
    width: calc(100% + 25px);
    padding-right: 25px;
    box-sizing: border-box;
    top: 0;
    bottom: 0;

    @media (min-width: 992px)
      position: relative
      overflow-y: visible;
      width auto
      padding 0
      top: auto;
      bottom: auto;

  &__link
    display: block
    font-size: 20px
    text-align: center
    padding: 8px 20px 11px
    line-height: 1.2
    color: #000
    transition: .27s all
    //padding: 8px 8px 11px calc(8px + 10vw)

    @media (min-width: 992px)
      font-size: 18px
      text-align: left

    &:hover
      text-decoration: none
      color: #000
      @media (hover: hover) and (min-width: 992px)
        color: #D79E0A

    &.active
      color: #fff
      background: #D79E0A

      &:hover
        cursor default
        color: #fff

  &__closer
    @media (min-width: 992px)
      display: none

.goods
  display: flex
  flex-wrap: wrap
  gap 37px 2%
  max-width: 1110px
  padding: 61px 0 0
  align-content: flex-start;

  @media (min-width: 380px)
    margin: 0 20px

  @media (min-width: 992px)
    gap 87px 27px
    margin: 0

  &__item
    display: flex;
    flex-wrap: wrap;
    width 100%
    background: #E7E2E2

    @media (min-width: 480px)
      width 49%

    @media (min-width: 740px)
      width 32%

    @media (min-width: 992px)
      width 256px

  &__item-img
    width: 100%
    align-self: flex-start;

    @media (min-width: 480px)
      max-height: 160px;
      object-fit: cover;

  &__item-box
    padding: 13px 17px
    display: flex
    flex-wrap: wrap
    align-items: flex-end;
    font-size: 18px;
    line-height: 1.2
    color #000

  &__item-name
    font-weight: 700;
    width: 100%
    padding: 0 0 11px

  &__item-text
    font-size: 12px;
    width: 100%
    padding: 0 0 15px
    min-height: 28px

  &__item-price
    color #FE3602
    padding: 0 0 20px
    width 50%

    span
      display: block
      text-decoration: line-through
      font-size: 14px;
      opacity: .65;

  &__item-weight
    color #B8860B
    padding: 0 0 20px
    width 50%
    text-align: right

  &__item-add
    display block
    padding: 7px 10px 10px
    font-size: 16px;
    background: #D79E0A
    border 1px solid #D79E0A
    border-radius: 20px
    width 100%
    text-align: center
    color #fff
    transition: .27s all

    &:hover
      text-decoration: none
      color #fff
      @media (hover: hover) and (min-width: 992px)
        color #D79E0A
        background transparent

.order
  position: fixed
  right: 0
  top 0
  height 100%
  width 100%
  background: #E7E2E2
  color: #000
  line-height: 1.2;
  z-index: 999;
  box-sizing: border-box;
  padding: 33px 20px
  transform: translate(100%,0);
  opacity 0
  transition: .27s all

  @media (min-width: 580px)
    padding: 33px 46px 68px 54px

  @media (min-width: 992px)
    max-width: 507px

  &.show
    transform: translate(0,0);
    opacity 1

  &__wrap
    position: absolute;
    overflow: hidden
    top: 46px
    bottom:23px
    right 20px
    left: 20px

    @media (min-width: 580px)
      top: 33px
      bottom:68px
      right 46px
      left: 54px

  &__box
    overflow-y: scroll
    position: absolute;
    width: calc(100% + 25px);
    padding-right: 25px;
    padding-bottom: 5px;
    box-sizing: border-box;
    top: 0;
    bottom: 0;

  &__loader
    stroke: #212836;
    display: none;
    height: 100px;

  &__answer
    text-align: center
    font-size: 22px
    display: none

    &--error
      color red

  &__title
    font-size: 25px
    font-weight: 700
    padding: 0 0 34px

  &__form
    background: none

  &__items
    padding: 24px 0 0;

  &__item
    padding 0 0 28px
    display: flex
    align-items: flex-start;
    gap 0 5px
    flex-wrap: wrap

    @media (min-width: 580px)
      padding 0 0 12px
      flex-wrap: nowrap

  &__item-title
    font-size: 18px
    line-height: 1.2

  &__item-weight
    color: #b8860b;
    font-size: 13px
    padding: 0 0 7px

    @media (min-width: 580px)
      font-size: inherit

  &__cost
    color: #fe3602;
    width: 75px
    text-align: right
    font-size: 18px
    line-height: 35px;
    white-space: nowrap

  &__item-count
    display: block
    border-radius: 50%
    width: 22px
    height: 22px
    border 1px solid #000
    text-align: center
    font-size: 24px
    box-sizing: border-box
    transition: .27s all
    position: relative

    &:hover
      text-decoration: none
      @media (hover: hover) and (min-width: 992px)
        border-color #b8860b

      &:before,
      &:after
        @media (hover: hover) and (min-width: 992px)
          background: #b8860b

    &:before,
    &:after
      content: '';
      position: absolute;
      width: 70%;
      height: 2px;
      background: #000;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -1px);
      transition: .27s all

    &--plus&:before
      transform: translate(-50%, -1px) rotate(90deg);

  &__item-column

    &:nth-child(1)
      width: 100%
      text-align: center;
      margin: auto 0;

      @media (min-width: 580px)
        width: auto
        text-align: left;

    &--count
      display: flex
      gap 0 6px
      align-items: center;
      margin: 0 0 0 auto

    &--delete
      display: flex
      align-items: center;
      padding: 0 0 0 5px
      height: 34px;
      margin 0 auto 0 0

      @media (min-width: 580px)
        margin 0
      
  &__delivery
    display: flex
    justify-content: space-between
    font-size: 18px

  &__delivery-column:nth-child(2)
    text-align: right
    color: #fe3602;

  &__sum
    display: flex
    justify-content: space-between
    padding: 8px 0 38px

  &__sum-title
    font-size: 22px
    font-weight: 700

  &__sum-text
    font-size: 22px
    text-align: right
    color: #fe3602;

  &__link-delete
    display: block
    position: relative
    border 1px solid #000
    border-radius 50%
    width: 22px
    height: 22px
    box-sizing: border-box
    transition: .27s border;

    &:hover
      @media (hover: hover) and (min-width: 992px)
        border-color #fe3602

      &:after,
      &:before
        background: #fe3602

    &:before,
    &:after
      content: ""
      position: absolute;
      top: 2px;
      right: 9px;
      width: 1px;
      height: 80%;
      content: '';
      transition: .27s background;
      background: #000;

    &:before
      transform: rotate(-45deg);

    &:after
      transform: rotate(45deg);



.form
  &__box
    display: flex
    gap 5px 11px
    padding 0 0 20px
    flex-wrap: wrap

    @media (min-width: 420px)
      flex-wrap: nowrap

    &--date
      display: none

      @media (min-width: 420px)
        padding-left 131px

  &__box-column

    &:nth-child(1)
      width: 100%

      @media (min-width: 420px)
        flex-shrink: 0;
        width 120px

    &:nth-child(2)
      width: 100%

    &--vertical-center
      align-self: center

  &__label
    font-size: 16px

  &__input
    border 1px solid #000
    height: 35px
    padding 0 8px
    background: transparent
    width 100%
    font-size: 18px
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    &--time,
    &--count
      width 58px

    &--date
      width 160px
      margin: 0 0 12px

    &--hide
      display none

  &__link-checkbox,
  &__radiobutton-item
    display: block
    color #000
    padding: 0 0 0 28px
    position: relative
    line-height: 22px;
    transition: .27s all

    &:hover
      text-decoration: none
      color #000
      @media (hover: hover) and (min-width: 992px)
        color: #d79e0a

    &:before,
    &:after
      content: ""
      position: absolute
      left: 0
      top 1px
      box-sizing: border-box;

    &:before
      border 1px solid #000
      width 20px
      height: 20px

    &:after
      width: 8px
      height: 8px
      background: transparent
      margin: 6px
      transition: .27s all

    &.checked:after
      background: #000

  &__radiobutton-item

    &:before,
    &:after
      border-radius: 50%

  &__divide
    padding: 17px 0 39px

    &:before
      content: ""
      display block
      height: 1px
      background: #000

  &__submit
    display: block
    transition: .27s all;
    text-align: center;
    color: #fff;
    border: 1px solid #d79e0a;
    border-radius: 30px;
    background: #d79e0a;
    font-size: 24px
    padding: 14px 0 16px
    cursor: pointer
    width 100%

    &:hover
      @media (hover: hover) and (min-width: 992px)
        background: transparent;
        color: #d79e0a;

    &.disabled
      opacity: .35;
      cursor default
      color: #fff;
      background: #d79e0a;

      &:hover
        @media (hover: hover) and (min-width: 992px)
          background: #d79e0a;
          color: #fff;

.img-loader
  width: 100%;
  height: 100%;