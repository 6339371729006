.uk-position-center {
  top: 75%;
}
@media (hover: hover) and (min-width: 992px) {
  .uk-position-center {
    top: 60%;
  }
}
.uk-height-large {
  height: 280px;
}
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
  color: #d79e0a;
}
.woocommerce .uk-section {
  padding-top: 0;
}
.promo > div {
  padding: 150px 15% 2px;
}
@media (hover: hover) and (min-width: 992px) {
  .promo > div {
    padding: 105px 15% 50px;
  }
}
.woocommerce a.remove:hover {
  background: transparent;
}
body.hidden {
  overflow: hidden;
}
.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}
.goods__item-box .added_to_cart.wc-forward {
  display: none;
}
.popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.9);
  z-index: 9999;
  min-height: 520px;
}
.popup__layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.popup__text-box {
  max-width: 480px;
  margin: 0 auto;
  padding: 25px 20px;
  text-align: center;
  background: #fff;
  font-size: 18px;
}
.popup__box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.popup__link {
  display: block;
  max-width: 220px;
  width: 100%;
  text-align: center;
  color: #fff;
  border: 1px solid #d79e0a;
  background: #d79e0a;
  border-radius: 20px;
  margin: 0 auto 20px;
  padding: 7px 10px 10px;
  text-decoration: none;
  transition: 0.27s all;
}
.popup__link:last-child {
  margin: 0 auto;
}
.popup__link:hover {
  text-decoration: none;
  color: #fff;
}
@media (hover: hover) and (min-width: 992px) {
  .popup__link:hover {
    background: transparent;
    color: #d79e0a;
  }
}
.popup__form {
  max-width: 28rem;
  margin: 0 auto;
}
.popup__input {
  margin: 2.4rem 0 0;
  height: 5.8rem;
  font-size: 1.8rem;
}
.popup__title {
  font-size: 24px;
  padding: 0 0 20px;
}
.link-booking {
  font-size: 0;
  border-radius: 50%;
  padding: 4px;
  fill: #b8860b;
}
@media (min-width: 992px) {
  .link-booking {
    font-size: inherit;
    border-radius: 0;
    padding: 0 15px;
  }
}
@media (min-width: 992px) {
  .link-booking svg {
    display: none;
  }
}
.link-basket {
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
  background: rgba(34,34,34,0.8);
  padding: 2px 15px 2px 45px;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 82px;
  right: 0;
  border: 1px solid #fff;
  border-radius: 20px;
  transition: 0.27s all;
}
@media (min-width: 992px) {
  .link-basket {
    margin: 0 18px 0 0;
    position: relative;
    top: auto;
    right: auto;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .link-basket:hover {
    border: 1px solid #d79e0a;
  }
}
.link-basket__ico {
  position: absolute;
  left: 10px;
  top: 50%;
  margin: -11px 0 0;
  width: 22px;
  height: 22px;
  fill: #fff;
  transition: 0.27s all;
}
@media (hover: hover) and (min-width: 992px) {
  .link-basket:hover .link-basket__ico {
    fill: #d79e0a;
  }
}
.link-basket span {
  color: #d79e0a;
}
.closer {
  position: absolute;
  right: 30px;
  top: 18px;
  width: 24px;
  height: 24px;
  z-index: 2;
}
@media (min-width: 580px) {
  .closer {
    top: 30px;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .closer:hover:before,
  .closer:hover:after {
    background: #b8860b;
  }
}
.closer:before,
.closer:after {
  content: "";
  position: absolute;
  top: -3px;
  right: 10px;
  width: 4px;
  height: 130%;
  content: '';
  transition: 0.27s background;
  border-radius: 3px;
  background: #000;
}
.closer:before {
  transform: rotate(-45deg);
}
.closer:after {
  transform: rotate(45deg);
}
.menu {
  background: #222;
}
.content {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  background: #222;
  gap: 0 44px;
  padding: 0 0 180px;
}
.dishes-link {
  position: fixed;
  background: #d79e0a;
  color: #fff;
  font-size: 15px;
  top: 50%;
  left: -4px;
  border-radius: 10px 10px 0 0;
  padding: 7px 12px;
  letter-spacing: 2px;
  transform: rotate(90deg) translate(-50%, 50%);
}
.dishes-link:hover {
  color: #fff;
  text-decoration: none;
}
@media (min-width: 992px) {
  .dishes-link {
    display: none;
  }
}
.dishes {
  background: #e7e2e2;
  display: flex;
  flex-direction: column;
  gap: 14px 0;
  padding: 42px 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transform: translate(-100%, 0);
  opacity: 0;
  z-index: 999;
  transition: 0.27s all;
}
.dishes.show {
  transform: translate(0, 0);
  opacity: 1;
}
@media (min-width: 992px) {
  .dishes {
    width: 320px;
    position: relative;
    max-width: 418px;
    min-width: 320px;
    width: auto;
    transform: none;
    opacity: 1;
    z-index: 1;
  }
}
.dishes__wrap {
  position: absolute;
  top: 46px;
  bottom: 23px;
  right: 0;
  left: 0;
  overflow: hidden;
}
@media (min-width: 580px) {
  .dishes__wrap {
    top: 33px;
    bottom: 68px;
  }
}
@media (min-width: 992px) {
  .dishes__wrap {
    position: relative;
    top: auto;
    bottom: auto;
    overflow: auto;
  }
}
.dishes__box {
  position: absolute;
  overflow-y: scroll;
  width: calc(100% + 25px);
  padding-right: 25px;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
}
@media (min-width: 992px) {
  .dishes__box {
    position: relative;
    overflow-y: visible;
    width: auto;
    padding: 0;
    top: auto;
    bottom: auto;
  }
}
.dishes__link {
  display: block;
  font-size: 20px;
  text-align: center;
  padding: 8px 20px 11px;
  line-height: 1.2;
  color: #000;
  transition: 0.27s all;
}
@media (min-width: 992px) {
  .dishes__link {
    font-size: 18px;
    text-align: left;
  }
}
.dishes__link:hover {
  text-decoration: none;
  color: #000;
}
@media (hover: hover) and (min-width: 992px) {
  .dishes__link:hover {
    color: #d79e0a;
  }
}
.dishes__link.active {
  color: #fff;
  background: #d79e0a;
}
.dishes__link.active:hover {
  cursor: default;
  color: #fff;
}
@media (min-width: 992px) {
  .dishes__closer {
    display: none;
  }
}
.goods {
  display: flex;
  flex-wrap: wrap;
  gap: 37px 2%;
  max-width: 1110px;
  padding: 61px 0 0;
  align-content: flex-start;
}
@media (min-width: 380px) {
  .goods {
    margin: 0 20px;
  }
}
@media (min-width: 992px) {
  .goods {
    gap: 87px 27px;
    margin: 0;
  }
}
.goods__item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #e7e2e2;
}
@media (min-width: 480px) {
  .goods__item {
    width: 49%;
  }
}
@media (min-width: 740px) {
  .goods__item {
    width: 32%;
  }
}
@media (min-width: 992px) {
  .goods__item {
    width: 256px;
  }
}
.goods__item-img {
  width: 100%;
  align-self: flex-start;
}
@media (min-width: 480px) {
  .goods__item-img {
    max-height: 160px;
    object-fit: cover;
  }
}
.goods__item-box {
  padding: 13px 17px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
}
.goods__item-name {
  font-weight: 700;
  width: 100%;
  padding: 0 0 11px;
}
.goods__item-text {
  font-size: 12px;
  width: 100%;
  padding: 0 0 15px;
  min-height: 28px;
}
.goods__item-price {
  color: #fe3602;
  padding: 0 0 20px;
  width: 50%;
}
.goods__item-price span {
  display: block;
  text-decoration: line-through;
  font-size: 14px;
  opacity: 0.65;
}
.goods__item-weight {
  color: #b8860b;
  padding: 0 0 20px;
  width: 50%;
  text-align: right;
}
.goods__item-add {
  display: block;
  padding: 7px 10px 10px;
  font-size: 16px;
  background: #d79e0a;
  border: 1px solid #d79e0a;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: 0.27s all;
}
.goods__item-add:hover {
  text-decoration: none;
  color: #fff;
}
@media (hover: hover) and (min-width: 992px) {
  .goods__item-add:hover {
    color: #d79e0a;
    background: transparent;
  }
}
.order {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #e7e2e2;
  color: #000;
  line-height: 1.2;
  z-index: 999;
  box-sizing: border-box;
  padding: 33px 20px;
  transform: translate(100%, 0);
  opacity: 0;
  transition: 0.27s all;
}
@media (min-width: 580px) {
  .order {
    padding: 33px 46px 68px 54px;
  }
}
@media (min-width: 992px) {
  .order {
    max-width: 507px;
  }
}
.order.show {
  transform: translate(0, 0);
  opacity: 1;
}
.order__wrap {
  position: absolute;
  overflow: hidden;
  top: 46px;
  bottom: 23px;
  right: 20px;
  left: 20px;
}
@media (min-width: 580px) {
  .order__wrap {
    top: 33px;
    bottom: 68px;
    right: 46px;
    left: 54px;
  }
}
.order__box {
  overflow-y: scroll;
  position: absolute;
  width: calc(100% + 25px);
  padding-right: 25px;
  padding-bottom: 5px;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
}
.order__loader {
  stroke: #212836;
  display: none;
  height: 100px;
}
.order__answer {
  text-align: center;
  font-size: 22px;
  display: none;
}
.order__answer--error {
  color: #f00;
}
.order__title {
  font-size: 25px;
  font-weight: 700;
  padding: 0 0 34px;
}
.order__form {
  background: none;
}
.order__items {
  padding: 24px 0 0;
}
.order__item {
  padding: 0 0 28px;
  display: flex;
  align-items: flex-start;
  gap: 0 5px;
  flex-wrap: wrap;
}
@media (min-width: 580px) {
  .order__item {
    padding: 0 0 12px;
    flex-wrap: nowrap;
  }
}
.order__item-title {
  font-size: 18px;
  line-height: 1.2;
}
.order__item-weight {
  color: #b8860b;
  font-size: 13px;
  padding: 0 0 7px;
}
@media (min-width: 580px) {
  .order__item-weight {
    font-size: inherit;
  }
}
.order__cost {
  color: #fe3602;
  width: 75px;
  text-align: right;
  font-size: 18px;
  line-height: 35px;
  white-space: nowrap;
}
.order__item-count {
  display: block;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: 1px solid #000;
  text-align: center;
  font-size: 24px;
  box-sizing: border-box;
  transition: 0.27s all;
  position: relative;
}
.order__item-count:hover {
  text-decoration: none;
}
@media (hover: hover) and (min-width: 992px) {
  .order__item-count:hover {
    border-color: #b8860b;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .order__item-count:hover:before,
  .order__item-count:hover:after {
    background: #b8860b;
  }
}
.order__item-count:before,
.order__item-count:after {
  content: '';
  position: absolute;
  width: 70%;
  height: 2px;
  background: #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -1px);
  transition: 0.27s all;
}
.order__item-count--plus.order__item-count:before {
  transform: translate(-50%, -1px) rotate(90deg);
}
.order__item-column:nth-child(1) {
  width: 100%;
  text-align: center;
  margin: auto 0;
}
@media (min-width: 580px) {
  .order__item-column:nth-child(1) {
    width: auto;
    text-align: left;
  }
}
.order__item-column--count {
  display: flex;
  gap: 0 6px;
  align-items: center;
  margin: 0 0 0 auto;
}
.order__item-column--delete {
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  height: 34px;
  margin: 0 auto 0 0;
}
@media (min-width: 580px) {
  .order__item-column--delete {
    margin: 0;
  }
}
.order__delivery {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.order__delivery-column:nth-child(2) {
  text-align: right;
  color: #fe3602;
}
.order__sum {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 38px;
}
.order__sum-title {
  font-size: 22px;
  font-weight: 700;
}
.order__sum-text {
  font-size: 22px;
  text-align: right;
  color: #fe3602;
}
.order__link-delete {
  display: block;
  position: relative;
  border: 1px solid #000;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  transition: 0.27s border;
}
@media (hover: hover) and (min-width: 992px) {
  .order__link-delete:hover {
    border-color: #fe3602;
  }
}
.order__link-delete:hover:after,
.order__link-delete:hover:before {
  background: #fe3602;
}
.order__link-delete:before,
.order__link-delete:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 9px;
  width: 1px;
  height: 80%;
  content: '';
  transition: 0.27s background;
  background: #000;
}
.order__link-delete:before {
  transform: rotate(-45deg);
}
.order__link-delete:after {
  transform: rotate(45deg);
}
.form__box {
  display: flex;
  gap: 5px 11px;
  padding: 0 0 20px;
  flex-wrap: wrap;
}
@media (min-width: 420px) {
  .form__box {
    flex-wrap: nowrap;
  }
}
.form__box--date {
  display: none;
}
@media (min-width: 420px) {
  .form__box--date {
    padding-left: 131px;
  }
}
.form__box-column:nth-child(1) {
  width: 100%;
}
@media (min-width: 420px) {
  .form__box-column:nth-child(1) {
    flex-shrink: 0;
    width: 120px;
  }
}
.form__box-column:nth-child(2) {
  width: 100%;
}
.form__box-column--vertical-center {
  align-self: center;
}
.form__label {
  font-size: 16px;
}
.form__input {
  border: 1px solid #000;
  height: 35px;
  padding: 0 8px;
  background: transparent;
  width: 100%;
  font-size: 18px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form__input--time,
.form__input--count {
  width: 58px;
}
.form__input--date {
  width: 160px;
  margin: 0 0 12px;
}
.form__input--hide {
  display: none;
}
.form__link-checkbox,
.form__radiobutton-item {
  display: block;
  color: #000;
  padding: 0 0 0 28px;
  position: relative;
  line-height: 22px;
  transition: 0.27s all;
}
.form__link-checkbox:hover,
.form__radiobutton-item:hover {
  text-decoration: none;
  color: #000;
}
@media (hover: hover) and (min-width: 992px) {
  .form__link-checkbox:hover,
  .form__radiobutton-item:hover {
    color: #d79e0a;
  }
}
.form__link-checkbox:before,
.form__radiobutton-item:before,
.form__link-checkbox:after,
.form__radiobutton-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  box-sizing: border-box;
}
.form__link-checkbox:before,
.form__radiobutton-item:before {
  border: 1px solid #000;
  width: 20px;
  height: 20px;
}
.form__link-checkbox:after,
.form__radiobutton-item:after {
  width: 8px;
  height: 8px;
  background: transparent;
  margin: 6px;
  transition: 0.27s all;
}
.form__link-checkbox.checked:after,
.form__radiobutton-item.checked:after {
  background: #000;
}
.form__radiobutton-item:before,
.form__radiobutton-item:after {
  border-radius: 50%;
}
.form__divide {
  padding: 17px 0 39px;
}
.form__divide:before {
  content: "";
  display: block;
  height: 1px;
  background: #000;
}
.form__submit {
  display: block;
  transition: 0.27s all;
  text-align: center;
  color: #fff;
  border: 1px solid #d79e0a;
  border-radius: 30px;
  background: #d79e0a;
  font-size: 24px;
  padding: 14px 0 16px;
  cursor: pointer;
  width: 100%;
}
@media (hover: hover) and (min-width: 992px) {
  .form__submit:hover {
    background: transparent;
    color: #d79e0a;
  }
}
.form__submit.disabled {
  opacity: 0.35;
  cursor: default;
  color: #fff;
  background: #d79e0a;
}
@media (hover: hover) and (min-width: 992px) {
  .form__submit.disabled:hover {
    background: #d79e0a;
    color: #fff;
  }
}
.img-loader {
  width: 100%;
  height: 100%;
}
